import { Client, Locale } from 'appwrite';

const { VITE_APPWRITE_ENDPOINT, VITE_APPWRITE_PROJECT } = import.meta.env;

const client = new Client();

client.setEndpoint(VITE_APPWRITE_ENDPOINT).setProject(VITE_APPWRITE_PROJECT);

const locale = new Locale(client);

export { client, locale };
